import SearchTabs from '@/Components/search-panel/search-tabs';
import { GImage } from '../../g-image';
import { AppLink } from '@/Components/link/link';
import { TPromotedCard } from './types';

type props = {
  tab: string;
  promotedCardData: TPromotedCard[];
};

function ProductCardPromoted({ tab, promotedCardData }: props): JSX.Element {
  const promotedWorkData = promotedCardData.filter(
    (item) => item.type === 'work'
  )[0];
  const promotedStudyData = promotedCardData.filter(
    (item) => item.type === 'study'
  )[0];
  const link =
    tab === SearchTabs.WORK
      ? promotedWorkData.url || ''
      : promotedStudyData.url || '';

  return (
    <AppLink href={link} target={link.startsWith('http') ? '_blank' : '_self'}>
      <div className="rounded-md overflow-hidden bg-light-600 max-w-md lg:max-w-46rem mx-auto flex flex-col lg:grid lg:grid-cols-9rem-1fr lg:items-center">
        <div className="relative h-8.5rem lg:h-full lg:max-w-9rem">
          {tab === SearchTabs.WORK && (
            <GImage
              alt={promotedWorkData.titleMobile}
              path={promotedWorkData?.cloudinaryImage || ''}
              transformation="trip-cards"
              classes={`lg:object-center`}
              width="100%"
              height="100%"
              hasLoadingBackground
            />
          )}
          {tab === SearchTabs.STUDY && (
            <GImage
              alt={promotedStudyData.titleMobile}
              path={promotedStudyData?.cloudinaryImage || ''}
              transformation="trip-cards"
              classes={`lg:object-center`}
              width="100%"
              height="100%"
              hasLoadingBackground
            />
          )}

          <p className="absolute bottom-0 left-0 mb-2 mx-4 py-1 px-2 bg-light-600 text-dark-700 rounded-md lg:hidden ">
            {tab === SearchTabs.WORK && promotedWorkData.messageMobile}
            {tab === SearchTabs.STUDY && promotedStudyData.messageMobile}
          </p>
        </div>
        <div className="py-2.5 px-5 font-bold border border-light-900 rounded-b-md lg:rounded-l-none lg:flex lg:flex-col lg:justify-center lg:flex-grow">
          <h2 className="text-lg text-dark-900 mb-2">
            <span className="lg:hidden">
              {tab === SearchTabs.WORK && promotedWorkData.titleMobile}
              {tab === SearchTabs.STUDY && promotedStudyData.titleMobile}
            </span>
            <span className="hidden lg:block">
              {tab === SearchTabs.WORK && promotedWorkData.titleDesktop}
              {tab === SearchTabs.STUDY && promotedStudyData.titleDesktop}
            </span>
          </h2>
          <p className="hidden lg:block text-base leading-5 font-normal text-dark-700">
            {tab === SearchTabs.WORK && promotedWorkData.messageDesktop}
            {tab === SearchTabs.STUDY && promotedStudyData.messageDesktop}
          </p>
          <div className="flex justify-center items-center text-sm leading-4.5 text-teal-900 mt-1 max-w-fit">
            <div>See trip</div>
            <i className="icon-right text-3xs ml-1"></i>
          </div>
        </div>
      </div>
    </AppLink>
  );
}

export { ProductCardPromoted };
