import { InsuranceBannerTypes } from './types';

const mockData: InsuranceBannerTypes = {
  image: 'new-website/static/homepage/global-travel-cover/banner.webp',
  title: 'International Travel & Medical Insurance',
  description:
    'Designed by & for young people exploring the world, get Global Travel Cover for easy peace of mind. Buy & renew from abroad too!',
  logo: 'new-website/static/homepage/global-travel-cover/gtc-logo.svg',
};

export { mockData };
