import { GImage } from '@/Components/g-image';
const LeadingBrand = () => {
  return (
    <section className="mb-3.75 lg:mb-20 bg-light-700 py-6 md:py-8">
      <div className="max-w-6.75xl m-auto">
        <div className="mx-5 sm:mx-8 xl:mx-10 flex flex-col items-center justify-between lg:flex-row">
          <div className="font-bold text-3xl leading-8 mb-8 text-dark-900 w-full md:flex-1 md:text-center lg:mb-0 lg:text-left">
            Leading brands we sell{' '}
          </div>
          <div className="flex flex-col gap-9 w-full md:flex-[3] md:justify-center md:flex-row md:gap-x-10 lg:justify-end lg:gap-x-16">
            <div className="flex justify-around items-center md:gap-x-10 lg:gap-x-16">
              <div className="h-9 w-auto">
                <GImage
                  path="new-website/static/homepage/brands/gadventureslogo.png"
                  styles={{ objectFit: 'contain' }}
                  width="100%"
                  height="100%"
                  alt="GAdventures Logo"
                />
              </div>
              <div className="h-16 md:h-20">
                <GImage
                  path="new-website/static/homepage/brands/trutravels.png"
                  styles={{ objectFit: 'contain' }}
                  width="100%"
                  height="100%"
                  alt="TruTravels Logo"
                />
              </div>
            </div>
            <div className="flex justify-around items-center md:gap-x-10 lg:gap-x-16">
              <div className="h-16 md:h-20 flex flex-col justify-center items-center">
                <GImage
                  path="new-website/static/homepage/brands/intro-travel.png"
                  styles={{ objectFit: 'contain' }}
                  width="100%"
                  height="100%"
                  alt="Intro travel Logo"
                />
              </div>
              <div className="h-16 md:h-20 flex flex-col justify-center items-center">
                <div className="h-[21px] w-[66px] md:h-[24px] md:w-[72px] mb-3 md:mb-4">
                  <GImage
                    path="new-website/icons/homepage/coming-soon.svg"
                    alt="topdeck coming soon badge"
                  />
                </div>
                <div className="h-10 mb-3 md:mb-6">
                  <GImage
                    path="new-website/static/homepage/brands/topdeck.png"
                    styles={{ objectFit: 'contain' }}
                    width="100%"
                    height="100%"
                    alt="Topdeck Logo"
                  />
                </div>
              </div>

              <div className="h-16 md:h-20 flex flex-col justify-center items-center">
                <div className="h-[21px] w-[66px] md:h-[24px] md:w-[72px] mb-3 md:mb-4">
                  <GImage
                    path="new-website/icons/homepage/coming-soon.svg"
                    alt="Contiki coming soon badge"
                  />
                </div>
                <div className="h-10 mb-3 md:mb-6">
                  <GImage
                    path="new-website/static/homepage/brands/contiki.png"
                    styles={{ objectFit: 'contain' }}
                    width="100%"
                    height="100%"
                    alt="Contiki travel Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LeadingBrand;
