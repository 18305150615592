import SearchTabs from '@/Components/search-panel/search-tabs';
import TabDivision from 'data/tab-division';

export const getDivisionsByTab = (activeTab: string) => {
  switch (activeTab) {
    case SearchTabs.WORK:
      return TabDivision.WORK;
    case SearchTabs.STUDY:
      return TabDivision.STUDY;
    case SearchTabs.TOUR:
      return TabDivision.TOUR;
    default:
      return TabDivision.WORK;
  }
};
