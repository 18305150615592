const TabDivision = {
  WORK: [
    'Working Holiday',
    'Volunteer',
    'Au Pair',
    'Teach',
    'Internship',
    'Summer Camp',
    'Tutor',
    'Remote',
  ],
  STUDY: ['Study'],
  TOUR: ['Tour'],
};
export default TabDivision;
