import { reviewsBannerTypes } from './types';

const mockData: reviewsBannerTypes = {
  image: {
    platforms: {
      'consumer-affairs':
        'new-website/static/homepage/reviews/consumer-affairs.svg',
      trustpilot: 'new-website/static/homepage/reviews/trustpilot.svg',
      reviews: 'new-website/static/homepage/reviews/reviews.svg',
      google: 'new-website/static/homepage/reviews/google.svg',
    },
  },
};

export { mockData };
