interface imagePositionByDivisionType {
  [key: string]: string;
}

const imagePositionByDivision: imagePositionByDivisionType = {
  'volunteer-abroad': '-object-2.4',
  'summer-camp': '-object-5',
  'internship-abroad': '-object-6.3',
  'tutor-abroad': '-object-6.3',
  'working-holiday': 'object-center',
  'au-pair-abroad': 'object-center',
  'teach-abroad': 'object-center',
};

export { imagePositionByDivision };
