import { GWorldBannerDataTypes } from './types';

const gworldBannerData: GWorldBannerDataTypes = {
  image: 'new-website/static/homepage/gworld/banner-test.webp',
  title: 'gWorld - Your very first travel buddy',
  description:
    'Stay organised for your upcoming adventure. Plan your trip, access deals from the marketplace, learn a new language and even make friends on the same trip as you! All from the palm of your hands.',
};

export { gworldBannerData };
