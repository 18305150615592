import { SmartStorage } from '@/Lib/services/smart-storage/smart-storage';
import { StorageKeys } from '@/Lib/services/storage-keys';

export function getVisitedPages(): number[] | null {
  if (window) {
    const visitedPagesStore = SmartStorage.createWithLocalStorage().over(
      StorageKeys.VisitedPages
    );
    const gwatVisitedPages = visitedPagesStore.get();
    if (gwatVisitedPages) {
      const parsedVisitedPages = JSON.parse(gwatVisitedPages);
      if (parsedVisitedPages) {
        return parsedVisitedPages;
      }
    }
  }
  return null;
}
