export function divisionNameNormalizer(name: string) {
  switch (name.toLowerCase()) {
    case 'work & travel': {
      return 'Work';
    }
    case 'volunteer & travel': {
      return 'Volunteer';
    }
    case 'au pair & travel': {
      return 'Au Pair';
    }
    case 'teach & travel': {
      return 'Teach';
    }
    case 'intern & travel': {
      return 'Intern';
    }
    case 'summer camp': {
      return 'Camp';
    }
    case 'tutor & travel': {
      return 'Tutor';
    }
    default: {
      return name;
    }
  }
}
