import {
  GroupedRegionSearchPanel,
  RegionSearchPanel,
} from '@/Lib/types/region';

export const renderDestinationDropdownCounts = (count: number) =>
  `<p>${count} trips</p>`;

export const getGroupedRegions = (destinations: RegionSearchPanel[]) => {
  const groupedRegions: GroupedRegionSearchPanel[] = [];
  for (let item of destinations) {
    const indexIfFound = groupedRegions.findIndex(
      (element) => element.name === item.region
    );
    if (indexIfFound >= 0) {
      groupedRegions[indexIfFound].count++;
    } else {
      groupedRegions.push({
        name: item.region,
        count: 1,
        ...(item.activity_tags ? { activityTags: item.activity_tags } : {}),
      });
    }
  }
  return groupedRegions;
};
