export const ProductCardSmallSkeleton = () => {
  return (
    <div
      className="flex rounded-md overflow-hidden"
      style={{
        minWidth: '299px',
        boxShadow: '2px 2px 6px rgb(0 0 0 / 15%)',
      }}
    >
      <div className="bg-GreyEE animate-pulse w-6.375rem h-6.375rem m-1 rounded-md"></div>
      <div className="flex-grow py-2.5 px-3 flex flex-col justify-center gap-3">
        <div className="bg-GreyCC animate-pulse w-40 h-5 rounded-full"></div>
        <div className="bg-GreyDD animate-pulse w-28 h-3 rounded-full"></div>
        <div className="bg-GreyEE animate-pulse w-15 h-3 rounded-full"></div>
      </div>
    </div>
  );
};
