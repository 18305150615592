import { GImage } from '../../g-image';
import { GStarRating } from '../../g-star-rating';
import Favorite from '../../favorite-button';
import { Promotion, RecentDiscoveriesTrip } from '@/Lib/types/trip';
import { AppLink } from '@/Components/link/link';
import { useQuery } from '@apollo/client';
import { RECENT_DISCOVERIES_TRIP } from '@/Lib/graphql/queries/trip.query';
import { Price } from './price';
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useDynamicPrice } from '@/Lib/hooks/useDynamicPrice';
import { usePrice } from '@/Lib/hooks/usePrice';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useLocation } from '@/Lib/hooks/useLocation';
import { ProductCardSmallSkeleton } from './skeleton';

type props = {
  id: number;
  index: number;
};

function ProductCardSmall({ id, index }: props): JSX.Element {
  const { location } = useLocation();
  const { currency } = useCurrency();

  const { loading, data } = useQuery<{
    trips: { items: RecentDiscoveriesTrip[] };
  }>(RECENT_DISCOVERIES_TRIP, {
    variables: {
      ids: [String(id)],
    },
  });
  const trip = !loading && data ? data.trips.items[0] : null;
  const { promotions: appliedPromotions, getComputedPromos } =
    useAtomValue(promotionsAtom);

  const { discount, reducedDeposit } =
    getComputedPromos && trip
      ? getComputedPromos(
          trip.promotions,
          appliedPromotions as Promotion[],
          trip,
          trip?.prices[currency],
          trip?.deposit[currency] || 0,
          currency
        )
      : { discount: 0, reducedDeposit: 0 };
  const divisionInternalName = getInternalName(trip?.division || '');
  const dynamicPrice = useDynamicPrice(divisionInternalName);
  const basePrice = trip?.prices ?? {
    AUD: 0,
    CAD: 0,
    EUR: 0,
    GBP: 0,
    NZD: 0,
    USD: 0,
  };
  const { price, discountedPrice } = usePrice({
    basePrice: basePrice,
    dynamicPrice,
    discount,
  });

  const clickOnRecent = (index: number, trip: RecentDiscoveriesTrip) => {
    RudderAnalytics.recentDiscoveriesClicked({
      product_id: trip.id,
      product_name: trip.tripName,
      position: index + 1,
      country: location.country.name,
    });
  };

  return (
    <>
      {!loading && trip && (
        <AppLink href={trip.url}>
          <div
            onClick={() => clickOnRecent(index, trip)}
            className="flex rounded-md overflow-hidden"
            style={{
              minWidth: '299px',
              boxShadow: '2px 2px 6px rgb(0 0 0 / 15%)',
            }}
          >
            <div className="relative">
              <GImage
                path={trip.bannerImg}
                alt={trip.tripName}
                transformation="trip-cards"
                height="6.875rem"
                width="6.875rem"
                styles={{ minWidth: '6.875rem' }}
                classes="inline"
                hasLoadingBackground
                useGallery={true}
              />
              <Favorite
                deposit={
                  trip?.deposit[currency]
                    ? trip?.deposit[currency] - reducedDeposit
                    : 0
                }
                price={discountedPrice}
                revenue={discountedPrice}
                type="smallcard"
                trip={trip}
                originalPrice={price}
                originalDeposite={
                  trip?.deposit[currency] ? trip?.deposit[currency] : 0
                }
              />
            </div>
            <div className="flex-grow py-2.5 px-3 flex flex-col justify-between">
              <div className="text-base font-bold text-gray-900">
                {trip.tripName.length > 43
                  ? `${trip.tripName.substring(0, 43)}...`
                  : trip.tripName}
              </div>
              <div>
                {!(
                  trip.reviews?.count < 3 && Number(trip.reviews?.rating) < 3
                ) ? (
                  <div className="flex items-center mt-2 text-sm text-gray-600 space-x-1">
                    <div style={{ minWidth: '76px' }}>
                      <GStarRating
                        size={0.75}
                        rating={Number(trip.reviews?.rating || 0)}
                        translateY="-translate-y-0.5"
                      />
                    </div>
                    <span className="font-bold text-base leading-5">
                      {trip.reviews?.rating}
                    </span>
                    <span className="ml-1">({trip.reviews?.count})</span>
                  </div>
                ) : null}
                <Price
                  discount={discount}
                  price={price}
                  discountedPrice={discountedPrice}
                />
              </div>
            </div>
          </div>
        </AppLink>
      )}
      {loading && !trip && <ProductCardSmallSkeleton />}
      {!loading && !trip && null}
    </>
  );
}

export { ProductCardSmall };
