import { ProductCardSmall } from '@/Components/cards/product-card-small';
import { getVisitedPages } from '@/Lib/helpers/visited-pages';
import { useDevice } from '@/Lib/hooks/useDevice';
import { FC, useEffect, useState } from 'react';

const MAX_TRIPS_COUNT_MOBILE = 7;
const MAX_TRIPS_COUNT_DESKTOP = 4;

const getNumberOfTrips = (
  isMD: boolean,
  visitedPages: number[] | null
): number => {
  if (isMD) {
    return MAX_TRIPS_COUNT_DESKTOP;
  }

  if (!visitedPages) {
    return MAX_TRIPS_COUNT_MOBILE;
  }

  return Math.min(MAX_TRIPS_COUNT_MOBILE, visitedPages.length);
};

const RecentDiscoveries: FC = () => {
  const { isMD } = useDevice();
  const [visitedPages, setVisitedPages] = useState<number[] | null>(null);
  const maxNoOfTrips = getNumberOfTrips(isMD, visitedPages);

  useEffect(() => {
    setVisitedPages(getVisitedPages());
  }, []);

  return visitedPages &&
    Array.isArray(visitedPages) &&
    visitedPages.length > 0 ? (
    <section className="my-8 md:mx-10">
      <h1 className="text-3xl font-bold text-black mb-5 px-5 md:px-0">
        Your recent discoveries
      </h1>
      <div
        className="grid xl:gap-2 lg:grid-cols-3 xl:grid-cols-4-1fr overflow-scroll xl:overflow-visible hide-webkit-scrollbar gap-6 px-5 md:px-0 py-2"
        style={{
          gridTemplateColumns: `repeat(${maxNoOfTrips}, 1fr)`,
        }}
      >
        {visitedPages.slice(0, maxNoOfTrips).map((tripId, index) => (
          <ProductCardSmall id={tripId} key={tripId} index={index} />
        ))}
      </div>
    </section>
  ) : null;
};

export { RecentDiscoveries };
