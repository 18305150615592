import { GImage } from '../../g-image';
import { mockData as insuranceBannerData } from './data';

function InsuranceBanner(): JSX.Element {
  return (
    <div className="relative flex flex-col max-w-3.5xl overflow-hidden rounded-md mx-auto md:flex-row md:border md:border-light-900">
      <div className="absolute md:static md:flex-1-only w-full h-full md:w-auto md:h-auto">
        <GImage
          path={insuranceBannerData.image}
          alt="global travel cover"
          transformation="image-card"
          classes="object-left md:object-center w-full h-full"
          width="100%"
          height="100%"
          hasLoadingBackground
        />
      </div>
      <div className="relative py-10 px-6 bg-gradient-to-t from-pureblack via-transparent to-transparent md:flex-2-only md:py-6 md:px-10 md:from-transparent md:to-transparent md:via-transparent">
        <h2 className="bg-light-600 pt-1 px-2 font-bold text-xl leading-6 md:mb-1 md:bg-transparent md:p-0">
          {insuranceBannerData.title}
        </h2>
        <p className="relative text-base leading-5 text-dark-700 bg-light-600 -top-px py-1 px-2 md:bg-transparent md:static md:top-auto md:p-0">
          {insuranceBannerData.description}
        </p>
        <a
          href="https://globaltravelcover.com/"
          target="_blank"
          rel="noreferrer"
          className="inline-block mt-5 mb-20 bg-red-900 text-light-600 rounded-md text-xl py-2 px-5 font-bold leading-6 md:mb-0"
        >
          Get yours
        </a>
        <div className="absolute bottom-4 left-6 select-none md:bottom-4 md:right-4 md:left-auto">
          <GImage
            path={insuranceBannerData.logo}
            alt="global work and travel logo"
          />
        </div>
      </div>
    </div>
  );
}

export { InsuranceBanner };
