import { gql } from '@apollo/client';

export const TRENDING_TRIPS = gql`
  query TrendingTrips(
    $userCountry: String
    $userRegion: String
    $officeCountry: String
    $divisions: [String]
  ) {
    trendingTrips(
      userCountry: $userCountry
      userRegion: $userRegion
      officeCountry: $officeCountry
      divisions: $divisions
    ) {
      items {
        id
        division
        destination
        region
        code
        bannerImg
        bannerVideo
        bannerVideoCover
        tripName
        partner {
          id
          name
        }
        age {
          min
          max
        }
        duration {
          min
          max
        }
        prices {
          AUD
          CAD
          USD
          GBP
          EUR
          NZD
        }
        reviews {
          count
          rating
        }
        durationType
        tag
        activity_tags {
          id
          name
        }
        url
        promotions {
          code
          type
          expires
          percent
          init
          amount {
            AUD
            CAD
            USD
            GBP
            EUR
            NZD
          }
        }
      }
      length
      offset
    }
  }
`;
