import { DestinationCard } from '@/Components/cards/destination-card';
import { DestinationCardSkeleton } from '@/Components/cards/destination-card/skeleton';
import SearchTabs from '@/Components/search-panel/search-tabs';
import { DESTINATION_PAGES_MAPPING } from '@/Lib/graphql/queries/destinations.query';
import { POPULAR_DESTINATIONS } from '@/Lib/graphql/queries/popular-destinations';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useLocation } from '@/Lib/hooks/useLocation';
import {
  DestinationPagesMappingQuery,
  PopularDestinationData,
  PopularDestinationWithUrl,
} from '@/Lib/types/destination';
import { useQuery } from '@apollo/client';
import TabDivision from 'data/tab-division';
import { useEffect, useMemo, useState } from 'react';

type Props = {
  activeTab: divisionInputType;
};

type divisionInputType = 'WORK' | 'STUDY' | 'TOUR' | 'FLY';

const SelectDivision = (type: divisionInputType) => {
  return (
    (type === SearchTabs.WORK && TabDivision.WORK) ||
    (type === SearchTabs.STUDY && TabDivision.STUDY) ||
    (type === SearchTabs.TOUR && TabDivision.TOUR)
  );
};

const PopularDestination = ({ activeTab }: Props) => {
  const [activeTabDivisions, setActiveTabDivisions] = useState(SearchTabs.WORK);
  const { location } = useLocation();
  const { loading: popularDestinationsLoading, data: popularDestinationsData } =
    useQuery<PopularDestinationData>(POPULAR_DESTINATIONS, {
      variables: {
        country: location.country.name,
        divisions: SelectDivision(activeTabDivisions as divisionInputType),
      },
    });
  const {
    loading: destinationPagesMappingLoading,
    data: destinationPagesMappingData,
  } = useQuery<DestinationPagesMappingQuery>(DESTINATION_PAGES_MAPPING);

  const loading = popularDestinationsLoading || destinationPagesMappingLoading;

  const data = useMemo(() => {
    if (destinationPagesMappingLoading || popularDestinationsLoading)
      return null;
    if (!popularDestinationsData || !destinationPagesMappingData) return null;

    return popularDestinationsData.PopularDestinations.map((destination) => {
      const withUrl =
        destinationPagesMappingData.pages.destinationPagesMapping.find(
          (d) => d.internal_name === getInternalName(destination.destination)
        );
      const url =
        withUrl && withUrl.url && withUrl.url.length
          ? { url: withUrl.url }
          : { url: `/country/${getInternalName(destination.destination)}` };
      return { ...destination, ...url };
    });
  }, [
    destinationPagesMappingLoading,
    destinationPagesMappingData,
    popularDestinationsLoading,
    popularDestinationsData,
  ]);

  useEffect(() => {
    setActiveTabDivisions(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (
      popularDestinationsData &&
      popularDestinationsData?.PopularDestinations.length < 5
    ) {
      setActiveTabDivisions(SearchTabs.WORK);
    }
  }, [popularDestinationsData]);

  return (
    <section className="py-4 my-12 md:mx-10">
      <h2 className="text-3xl font-bold text-black mb-5 px-5 md:px-0">
        Popular destinations
      </h2>
      <div className="grid overflow-x-scroll overflow-y-hidden hide-webkit-scrollbar gap-4 lg:gap-8 grid-cols-3-1fr lg:grid-cols-5-1fr px-5 md:px-0">
        {!loading && data
          ? data?.map((destination: PopularDestinationWithUrl) => (
              <DestinationCard
                destination={destination}
                key={destination.destination}
              />
            ))
          : null}
      </div>
      <div className="grid overflow-x-scroll overflow-y-hidden hide-webkit-scrollbar gap-4 lg:gap-8 grid-cols-3-1fr lg:grid-cols-5-1fr px-5 md:px-0">
        {popularDestinationsLoading || !popularDestinationsData
          ? [...Array(5)].map((_, index) => (
              <DestinationCardSkeleton key={index} />
            ))
          : null}
      </div>
    </section>
  );
};
export default PopularDestination;
