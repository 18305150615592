import { Trips } from '@/Components/trips';
import { BasicTrip } from '@/Lib/types/trip';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { AppLink } from '@/Components/link/link';
import { useLocation } from '@/Lib/hooks/useLocation';
import { useDevice } from '@/Lib/hooks/useDevice';

type Props = {
  title: string;
  trips: BasicTrip[] | undefined;
  isLoading: boolean;
  divisions: string[];
  activeTab: string;
};

const TrendingTripsComponent = ({
  title,
  trips,
  isLoading,
  divisions,
  activeTab,
}: Props) => {
  const { location } = useLocation();
  const { isMD } = useDevice();

  const clickProductCard = (index: number, trip: BasicTrip) => {
    RudderAnalytics.trendingTripsClicked({
      product_id: trip.id,
      product_name: trip.tripName,
      position: index + 1,
      tab: activeTab.toLowerCase(),
      country: location.country.name,
    });
  };

  return (
    <section className="py-4 my-8 md:mx-10">
      <>
        <Trips
          title={title}
          trips={!isMD ? trips?.slice(0, 5) : trips?.slice(0, 4)}
          clickProductCard={(index: number, trip: BasicTrip) =>
            clickProductCard(index, trip)
          }
          isLoading={isLoading}
        />
        {!isLoading && trips && (
          <div className="mx-5 lg:mx-0">
            <AppLink
              href={{ pathname: '/search', query: { divisions } }}
              className="mt-4 font-bold text-left text-teal-900 underline cursor-pointer"
            >
              See more
            </AppLink>
          </div>
        )}
      </>
    </section>
  );
};

export { TrendingTripsComponent };
