import { useDevice } from '@/Lib/hooks/useDevice';
import { GImage } from '../../g-image';
import { mockData as appBannerData } from './data';
import { PhoneCodeData } from '@/Lib/types/phone-code';
import { SuperliteProductCard } from '@/Components/superlite-card/superlite-product-card';
import { SuperliteProductCardDesktop } from '@/Components/superlite-card/superlite-product-card-desktop';

// TODO: refactor this to remove SMS form component as it's not used

type Props = { data: PhoneCodeData };

function AppBanner({ data }: Props): JSX.Element {
  const { isBase, isSM, isMD, isLG } = useDevice();

  return (
    <>
      {isBase ? (
        <SuperliteProductCard
          largeHeading
          largeTitleGap={true}
          phonesClassName="!w-15.625"
        />
      ) : (
        <SuperliteProductCardDesktop />
      )}
    </>
  );
}

export { AppBanner };
