import { FC } from 'react';
import SearchTabs from '@/Components/search-panel/search-tabs';
import styles from '@/Components/search-panel/search-panel.module.css';
import { GImage, generateUrl } from '@/Components/g-image';
import Head from 'next/head';

type props = {
  activeTab: string;
  onTabChange: (tab: string) => void;
};

const SearchPanelTabs: FC<props> = ({ activeTab, onTabChange }) => {
  const isWorkActive = activeTab === SearchTabs.WORK;
  const isStudyActive = activeTab === SearchTabs.STUDY;
  const isTourActive = activeTab === SearchTabs.TOUR;

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={generateUrl('website/icons/page/home/work.svg')}
          as="image"
        />
        <link
          rel="preload"
          href={generateUrl('website/icons/page/home/study.svg')}
          as="image"
        />
        <link
          rel="preload"
          href={generateUrl('website/icons/page/home/tour.svg')}
          as="image"
        />
        <link
          rel="preload"
          href={generateUrl('website/icons/page/home/holiday.svg')}
          as="image"
        />
        <link
          rel="preload"
          href={generateUrl('website/icons/page/home/insurance.svg')}
          as="image"
        />
      </Head>
      <div className="flex flex-row justify-around md:gap-0 md:justify-start w-full lg:max-w-35rem text-center  m-auto">
        <div
          role={'button'}
          className={`h-4.375rem md:w-6rem ml-[0.5rem] px-[0.2rem] md:px-0 md:h-6rem leading-5 flex flex-col items-center justify-center ${
            isWorkActive && styles.activeTab
          }`}
          onClick={() => onTabChange(SearchTabs.WORK)}
        >
          <div
            className={
              'hidden md:block w-10 h-10 ' +
              (isWorkActive ? styles.activeSvg : '')
            }
          >
            <GImage
              path="website/icons/page/home/work.svg"
              alt="laptop icon"
              visibleByDefault
            />
          </div>
          <div
            className={
              'w-8 h-8 md:hidden ' + (isWorkActive ? styles.activeSvg : '')
            }
          >
            <GImage
              path="website/icons/page/home/work.svg"
              alt="laptop icon"
              visibleByDefault
            />
          </div>
          <div className="text-sm md:text-base !leading-5">Work</div>
        </div>
        <div
          role={'button'}
          className={`h-4.375rem md:w-6rem mx-2 px-[0.4rem] md:px-0 md:h-6rem leading-5 flex flex-col items-center justify-center ${
            activeTab === SearchTabs.STUDY && styles.activeTab
          }`}
          onClick={() => onTabChange(SearchTabs.STUDY)}
        >
          <div
            className={
              'hidden md:block w-10 h-10 ' +
              (isStudyActive ? styles.activeSvg : '')
            }
          >
            <GImage
              path="website/icons/page/home/study.svg"
              alt="college hat icon"
              visibleByDefault
            />
          </div>
          <div
            className={
              'w-8 h-8 md:hidden ' + (isStudyActive ? styles.activeSvg : '')
            }
          >
            <GImage
              path="website/icons/page/home/study.svg"
              alt="college hat icon"
              visibleByDefault
            />
          </div>
          <div className="text-sm md:text-base !leading-5">Study</div>
        </div>
        <div
          role={'button'}
          className={`h-4.375rem md:w-6rem px-[0.4rem] md:px-0 md:h-6rem leading-5 flex flex-col items-center justify-center ${
            activeTab === SearchTabs.TOUR && styles.activeTab
          }`}
          onClick={() => onTabChange(SearchTabs.TOUR)}
        >
          <div
            className={
              'hidden md:block w-10 h-10 ' +
              (isTourActive ? styles.activeSvg : '')
            }
          >
            <GImage
              path="website/icons/page/home/tour.svg"
              alt="small van icon"
              visibleByDefault
            />
          </div>
          <div
            className={
              'w-8 h-8 md:hidden ' + (isTourActive ? styles.activeSvg : '')
            }
          >
            <GImage
              path="website/icons/page/home/tour.svg"
              alt="small van icon"
              visibleByDefault
            />
          </div>

          <div className="text-sm md:text-base !leading-5">Tour</div>
        </div>
        <a
          href="https://dealsaway.com/?utm_source=gwat&utm_medium=homepage&utm_campaign=holiday"
          target="_blank"
          rel="noreferrer"
        >
          <div
            role={'button'}
            className={`h-4.375rem md:w-6rem mx-[0.4rem] px-[0.2rem] md:px-0 md:h-6rem leading-5 flex flex-col items-center justify-center relative  `}
          >
            <div className="hidden md:block w-10 h-10">
              <GImage
                path="website/icons/page/home/holiday.svg"
                alt="palm tree icon"
                visibleByDefault
              />
            </div>
            <div className="md:hidden w-8 h-8">
              <GImage
                path="website/icons/page/home/holiday.svg"
                alt="palm tree icon"
                visibleByDefault
              />
            </div>
            <div className="text-sm md:text-base !leading-5">Holiday</div>
          </div>
        </a>
        <a
          href="https://globaltravelcover.com/?utm_source=gwat&utm_medium=homepage&utm_campaign=insurance"
          target="_blank"
          rel="noreferrer"
        >
          <div
            role={'button'}
            className={`h-4.375rem md:w-6rem md:px-0 md:h-6rem leading-5 flex flex-col items-center justify-center relative `}
          >
            <div className="hidden md:block w-10 h-10">
              <GImage
                path="website/icons/page/home/insurance.svg"
                alt="protected umbrella icon"
                visibleByDefault
              />
            </div>
            <div className="md:hidden w-8 h-8">
              <GImage
                path="website/icons/page/home/insurance.svg"
                alt="protected umbrella icon"
                visibleByDefault
              />
            </div>
            <div className="text-sm md:text-base !leading-5">
              <span className="hidden md:inline">Insurance</span>
              <span className="inline md:hidden">Cover</span>
            </div>
          </div>
        </a>
        <a
          href="https://superlite.travel/?utm_source=gwat&utm_medium=homepage&utm_campaign=superlite"
          target="_blank"
          rel="noreferrer"
        >
          <div
            role={'button'}
            className={`h-4.375rem md:w-6rem px-[0.2rem] md:px-0 md:h-6rem leading-5 flex flex-col items-center justify-center relative `}
          >
            <div className="hidden md:block w-10 h-10">
              <GImage
                path="website/icons/page/home/superlite.svg"
                alt="protected umbrella icon"
                visibleByDefault
              />
            </div>
            <div className="md:hidden w-8 h-8">
              <GImage
                path="website/icons/page/home/superlite.svg"
                alt="protected umbrella icon"
                visibleByDefault
              />
            </div>
            <div className="text-sm md:text-base !leading-5">
              <span className="hidden md:inline">SuperLite</span>
              <span className="inline md:hidden">App</span>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default SearchPanelTabs;
