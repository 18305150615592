import { GImage } from '../../g-image';
import { AppLink } from '@/Components/link/link';

import { gworldBannerData } from './data';
import { useState } from 'react';
import { useDevice } from '@/Lib/hooks/useDevice';
import { ModalCentered } from '@/Components/modals/modal-centered';
import { PlayIcon } from '@/Assets/icons/play';

function GWorldBanner(): JSX.Element {
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const { isMD } = useDevice();
  const openVideoModal = () => setIsVideoOpen(true);
  return (
    <>
      <div className="relative flex flex-col lg:flex-row px-5 lg:pr-24 lg:pl-16 pb-7 pt-38vw lg:py-0 lg:h-100 sm:pt-30vw mt-50vw sm:mt-30vw lg:my-24 justify-center items-center bg-darkaqua rounded-md">
        <div className="absolute lg:relative -top-37vw sm:-top-28vw lg:top-0 lg:right-40 xl:right-8 flex-shrink lg:max-w-md lg:flex-1.2">
          <div className="mx-auto w-91vw sm:w-70vw lg:w-110">
            <GImage
              alt={gworldBannerData.title}
              path={gworldBannerData.image}
            />
          </div>
        </div>
        <div className="flex-1 text-light-600 xl:ml-36">
          <h2 className="text-3xl leading-8 font-bold mb-2.5">
            {gworldBannerData.title}
          </h2>
          <p className="text-base leading-5 text-light-700">
            {gworldBannerData.description}
          </p>
          <div className="flex items-center">
            <AppLink
              href="/gworld"
              className="inline-block mt-8 bg-transparent border border-light-600 rounded-md py-2 px-5 font-semibold"
            >
              Learn more
            </AppLink>
            <div
              className="inline-block mt-8 bg-transparent border border-light-600 rounded-md py-2 px-5 font-semibold ml-2.5 cursor-pointer "
              onClick={openVideoModal}
            >
              <div className="flex">
                <div className="mr-0.5 text-white w-6 h-6">
                  <PlayIcon />
                </div>
                Click to play
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCentered
        isModalOpen={isVideoOpen}
        setIsModalOpen={setIsVideoOpen}
        noPadding
        noCloseButton
      >
        <iframe
          width={isMD ? '840' : '320'}
          height={isMD ? '472' : '180'}
          src="https://www.youtube.com/embed/X7hnIwmWdz8?playsinline=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </ModalCentered>
    </>
  );
}

export { GWorldBanner };
