import { GImage } from '../../g-image';
import { ReviewsSection } from '../reviews-section';
import { mockData as reviewsBannerData } from './data';

function ReviewsBanner(): JSX.Element {
  return (
    <div className="bg-light-700">
      <div className="lg:max-w-6.75xl lg:mx-auto flex flex-col lg:flex-row items-center justify-between text-center p-5 px-5 md:px-10">
        <ReviewsSection alternative={true} />
        <div className="flex items-end lg:items-center gap-5 pt-3 lg:p-0">
          <div className="h-3 lg:h-4 transform -translate-y-0.5  lg:-translate-y-0.5">
            <GImage
              alt="consumer-affairs logo"
              path={reviewsBannerData.image.platforms['consumer-affairs']}
              classes="block"
              height="100%"
            />
          </div>
          <div className="h-5 lg:h-8 transform  lg:-translate-y-1">
            <GImage
              alt="trustpilot logo"
              path={reviewsBannerData.image.platforms.trustpilot}
              height="100%"
            />
          </div>
          <div className="h-4 lg:h-6  lg:-translate-y-0">
            <GImage
              alt="reviews logo"
              path={reviewsBannerData.image.platforms.reviews}
              height="100%"
            />
          </div>
          <div className="hidden md:block h-4 lg:h-6">
            <GImage
              alt="goolge logo"
              path={reviewsBannerData.image.platforms.google}
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { ReviewsBanner };
