import SearchTabs from '@/Components/search-panel/search-tabs';

export const getTitleByTab = (activeTab: string, country: string) => {
  const countryFrom = country.length > 0 ? ` from ${country}` : '';
  switch (activeTab) {
    case SearchTabs.WORK:
      return 'Trending trips' + countryFrom;
    case SearchTabs.STUDY:
      return 'Top study experiences';
    case SearchTabs.TOUR:
      return 'Top tours';
    default:
      return 'Trending trips' + countryFrom;
  }
};
