import { gql } from '@apollo/client';

export const POPULAR_DESTINATIONS = gql`
  query PopularDestinations($country: String, $divisions: [String]) {
    PopularDestinations(country: $country, divisions: $divisions) {
      destination
      popular
    }
  }
`;
