const DestinationCardSkeleton = () => {
  return (
    <div className="flex lg:flex-col lg:items-start items-center w-full min-w-13">
      <div className="w-20 h-20 min-w-5rem lg:w-full lg:h-80 mr-4 bg-light-800 animate-pulse rounded-md"></div>
      <div className="w-24 md:w-36 h-6 lg:mt-2.5 bg-light-800 animate-pulse rounded-md"></div>
    </div>
  );
};

export { DestinationCardSkeleton };
