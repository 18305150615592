import { FC, useEffect, useState } from 'react';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { DropdownMultivalue } from '@/Components/drop-down/multivalue';
import { MultiValue } from 'react-select';
import { TDropDownItem } from '@/Components/drop-down/types';
import { AppLink } from '@/Components/link/link';
import {
  Partner,
  TourOperatorAndDestinationSearchPanel,
} from '@/Lib/types/trip';
import uniqBy from 'lodash/uniqBy';
import { getGroupedRegions, renderDestinationDropdownCounts } from '../helpers';
import { GroupedRegionSearchPanel } from '@/Lib/types/region';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useLocation } from '@/Lib/hooks/useLocation';

const normalizeDropdownSelections = (selections: MultiValue<TDropDownItem>) => {
  return selections?.map((o) =>
    o.value.replace(/-/g, ' ').replace('abroad', '').trim().toLowerCase()
  );
};

const normalizeDestinationData = (
  destinations: TourOperatorAndDestinationSearchPanel[]
) => {
  const groupedRegions: GroupedRegionSearchPanel[] =
    getGroupedRegions(destinations);

  const destinationDropdownItems = groupedRegions.map(
    (item: GroupedRegionSearchPanel) => ({
      value: item.name,
      title: item.name,
      description: renderDestinationDropdownCounts(item.count),
      image: `new-website/region/${getInternalName(item.name)}.webp`,
    })
  );
  return destinationDropdownItems;
};

const normalizeOperatorDropdownItems = (
  operators: Partner[]
): TDropDownItem<number>[] => {
  return operators.map((operator) => ({
    title: operator.name,
    value: operator.name,
    image: `new-website/static/homepage/brands/${getInternalName(
      operator.name.replace(/\s*/g, '')
    )}.png`,
    data: operator.id,
  }));
};

const getOperators = (
  data: TourOperatorAndDestinationSearchPanel[]
): Partner[] => {
  const unique = uniqBy(data, (d) => d.partner.id);
  return unique.map((u) => u.partner);
};

type props = {
  data: TourOperatorAndDestinationSearchPanel[];
};

const filterDestinationsBasedOnOperator = (
  data: TourOperatorAndDestinationSearchPanel[],
  selectedOperators: TDropDownItem<number>[]
): TourOperatorAndDestinationSearchPanel[] => {
  const filteredDestinations = data.filter(
    (item) =>
      selectedOperators.findIndex((op) => op.data === item.partner.id) > -1
  );
  return filteredDestinations;
};

const TourSearchForm: FC<props> = ({ data }) => {
  const [selectedOperators, setSelectedOperators] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [operatorDropdownItems, setOperatorDropdownItems] = useState<
    TDropDownItem[]
  >(normalizeOperatorDropdownItems(getOperators(data)));
  const [destinationDropdownItems, setDestinationDropdownItems] = useState<
    TDropDownItem[]
  >(normalizeDestinationData(data));

  useEffect(() => {
    setOperatorDropdownItems(
      normalizeOperatorDropdownItems(getOperators(data))
    );
  }, [data]);

  useEffect(() => {
    const filteredDestinations: TourOperatorAndDestinationSearchPanel[] =
      filterDestinationsBasedOnOperator(
        data,
        selectedOperators as TDropDownItem<number>[]
      );
    if (filteredDestinations.length) {
      setDestinationDropdownItems(
        normalizeDestinationData(filteredDestinations)
      );
    } else {
      setDestinationDropdownItems(normalizeDestinationData(data));
    }
  }, [selectedOperators, data]);

  const { location } = useLocation();

  const tredingClick = () => {
    RudderAnalytics.findMyTripClicked({
      category: 'tour',
      operator: normalizeDropdownSelections(selectedOperators),
      destination: normalizeDropdownSelections(selectedDestinations),
      country: location.country.name,
    });
  };

  return (
    <>
      <h1 className="text-xl lg:text-4xl font-bold mb-1 md:mb-2.5">
        Tours & Adventures
      </h1>
      <p className="mb-5 text-darkgrey lg:text-lg lg:leading-6">
        {`No work, just travel. See, do & learn more on the best tours &
        adventures.`}
      </p>
      <div className="flex flex-col lg:flex-row gap-2.5">
        <DropdownMultivalue
          id="tour-search-form-experience"
          icon="icon-list"
          placeholder="Tour operator"
          options={operatorDropdownItems}
          dropdownName="search-panel-tour-experience"
          selectedOptions={selectedOperators}
          setSelectedOptions={setSelectedOperators}
          emptyOption={true}
          hideClearAll
        />
        <DropdownMultivalue
          id="tour-search-form-destination"
          icon="icon-location"
          placeholder="Destination"
          options={destinationDropdownItems}
          dropdownName="search-panel-tour-destination"
          selectedOptions={selectedDestinations}
          setSelectedOptions={setSelectedDestinations}
          emptyOption={true}
          hideClearAll
        />
        <AppLink
          href={{
            pathname: '/search',
            query: {
              destinations: normalizeDropdownSelections(selectedDestinations),
              operators: normalizeDropdownSelections(selectedOperators),
              divisions: 'tour',
            },
          }}
          onClick={() => tredingClick()}
          className="text-xl md:leading-1.875rem md:align-middle text-white font-semibold mt-3 md:mt-0 bg-red-900 rounded-md px-14 py-5 lg:py-3.5 hover:bg-redhover"
        >
          Find my trip
        </AppLink>
      </div>
    </>
  );
};

export default TourSearchForm;
