import { TRENDING_TRIPS } from '@/Lib/graphql/queries/trending-trip.query';
import { useQuery } from '@apollo/client';
import { TrendingTripQueryResult } from '@/Lib/types/trending-trip';
import { getDivisionsByTab } from '@/Lib/helpers/tab-division';
import { getTitleByTab } from '@/Lib/helpers/trending-trips';
import { useLocation } from '@/Lib/hooks/useLocation';
import { useOffice } from '@/Lib/hooks/office/useOffice';
import { TrendingTripsComponent } from './trips';

type Props = {
  activeTab: string;
};

const TendingTrips = ({ activeTab }: Props) => {
  const { location } = useLocation();
  const { office } = useOffice();
  const countryName = location.country.name;
  const region = location.region;

  const title = getTitleByTab(activeTab, location.country.name);
  const activeDivisions = getDivisionsByTab(activeTab);
  const divisions = activeDivisions.map((item) => item.toLocaleLowerCase());

  const { loading: tripsLoading, data: tripsData } =
    useQuery<TrendingTripQueryResult>(TRENDING_TRIPS, {
      variables: {
        userCountry: countryName,
        userRegion: region,
        officeCountry: office.office,
        divisions: activeDivisions,
      },
    });

  return (
    <TrendingTripsComponent
      title={title}
      trips={tripsData?.trendingTrips.items}
      isLoading={tripsLoading}
      divisions={divisions}
      activeTab={activeTab}
    />
  );
};
export default TendingTrips;
